import React, { useState } from "react"

import { graphql } from "gatsby"
import ElevatorContent from "../components/ElevatorContent"
import ElevatorHero from "../components/ElevatorHero"
import { MultiPageFormProvider } from "../components/ContextProviders/MultiPageFormProvider"
import ElevatorForm from "../components/elevatorInquiry/ElevatorForm"
import Layout from "../components/layout"
import PageEndBanner from "../components/PageEndBanner"

const Elevator = ({ pageContext, data: { elevator, miscString } }) => {
  const [open, setOpen] = useState(false)
  return (
    <Layout
      pageData={pageContext}
      seoTitle={elevator.seo?.seoTitle}
      seoDescription={elevator.seo?.seoDescription}
      seoImage={elevator.seo?.image?.seoImageUrl}
    >
      <ElevatorHero
        description={elevator.description}
        tag={elevator.tag}
        title={elevator.title}
        cta={miscString.nodes[0].getInquiry}
        open={open}
        setOpen={() => setOpen(!open)}
        heroimage={elevator.hero.gatsbyImageData}
      />
      <MultiPageFormProvider>
        <ElevatorForm open={open} setOpen={() => setOpen(false)} />
      </MultiPageFormProvider>
      {elevator.content?.value && (
        <ElevatorContent structuredData={elevator.content} />
      )}
      <PageEndBanner onClick={() => setOpen(!open)} />
    </Layout>
  )
}

export default Elevator

export const query = graphql`
  query elevatorQuery($id: String, $locale: String) {
    elevator: datoCmsElevator(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      title
      locale
      slug
      description
      tag
      hero {
        gatsbyImageData(height: 400)
      }
      content {
        value
        links {
          __typename
          id: originalId
          title
          slug
        }
        blocks {
          ... on DatoCmsConfrontationBlock {
            id: originalId
            __typename
            title
            data
            icon {
              gatsbyImageData(height: 35, width: 35)
            }
          }

          ... on DatoCmsFeatureList {
            id: originalId
            __typename
            feature {
              title
              icon {
                url
              }
              description
              content {
                value
              }
            }
          }
          ... on DatoCmsConfrontationTableBlock {
            id: originalId
            __typename
            content {
              value
              blocks {
                id: originalId
                __typename
                icon {
                  gatsbyImageData(height: 35, width: 35)
                }
                title
                data
              }
            }
          }
          ... on DatoCmsFeaturelistinline {
            id: originalId
            __typename
            feature1 {
              title
              icon {
                url
              }
              description
              content {
                value
              }
            }
            feature2 {
              title
              icon {
                url
              }
              description
              content {
                value
              }
            }
            feature3 {
              title
              icon {
                url
              }
              description
              content {
                value
              }
            }
          }
          ... on DatoCmsTwoColumnContentImage {
            id: originalId
            __typename
            title
            specs {
              specs
            }
            image {
              url
              gatsbyImageData(height: 500)
            }
            content {
              value
              links {
                __typename
                ... on DatoCmsElectronic {
                  __typename
                  title
                  id: originalId
                }
                ... on DatoCmsBlogPost {
                  __typename
                  title
                  id: originalId
                }
              }
            }
            cta
            link {
              __typename
              ... on DatoCmsCabinPage {
                id: originalId
              }
              ... on DatoCmsElectronicPage {
                id: originalId
              }
              ... on DatoCmsElectronic {
                id: originalId
              }
            }
          }
        }
      }

      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        getInquiry
        email
        name
        companyName
        email
        phoneNumber
        message
        send
      }
    }
  }
`
